import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import DangerBox from 'components/Web_User_Interface/720p_Series/Alarm/SMTP_Server/DangerBox';
import SMTPTable from 'components/Web_User_Interface/720p_Series/Alarm/SMTP_Server/smtpTable';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Alarm Menu // SMTP Server",
  "path": "/Web_User_Interface/720p_Series/Alarm/SMTP_Server/",
  "dateChanged": "2017-12-08",
  "author": "Mike Polinowski",
  "excerpt": "The alarm areas detect motion by a picture analysis that is sensitive to every change inside the video frame instead of employing an infrared or microwave sensor. You can setup up to 4 detection areas - just activate an area and draw its shape onto the image - and adjusts its sensitivity to set a threshold for trigger events.",
  "image": "./WebUI_720p_SearchThumb_Alarm_SMTP_Server.png",
  "social": "/images/Search/WebUI_720p_SearchThumb_Alarm_SMTP_Server.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_WebUI_white.webp",
  "chapter": "720p Web User Interface"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='Alarm Menu // SMTP Server' dateChanged='2017-12-08' author='Mike Polinowski' tag='INSTAR IP Camera' description='The alarm areas detect motion by a picture analysis that is sensitive to every change inside the video frame instead of employing an infrared or microwave sensor. You can setup up to 4 detection areas - just activate an area and draw its shape onto the image - and adjusts its sensitivity to set a threshold for trigger events.' image='/images/Search/WebUI_720p_SearchThumb_Alarm_SMTP_Server.png' twitter='/images/Search/WebUI_720p_SearchThumb_Alarm_SMTP_Server.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Web_User_Interface/720p_Serie/Alarm/SMTP_Server/' locationFR='/fr/Web_User_Interface/720p_Series/Alarm/SMTP_Server/' crumbLabel="SMTP Server" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "720p-web-user-interface",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#720p-web-user-interface",
        "aria-label": "720p web user interface permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`720p Web User Interface`}</h1>
    <h2 {...{
      "id": "alarm-menu",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#alarm-menu",
        "aria-label": "alarm menu permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Alarm Menu`}</h2>
    <h3 {...{
      "id": "smtp-server",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#smtp-server",
        "aria-label": "smtp server permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`SMTP Server`}</h3>
    <EuiSpacer mdxType="EuiSpacer" />
    <blockquote>
      <p parentName="blockquote">{`Simply use the INSTAR Preset if you don´t know the SMTP information of your Email provider. Choose the preset from the drop down menu and confirm. The Email recipient can be set in the Email Menu. Set-up this way, your camera will use an INSTAR Server to send alarm mails to your personal Email address. This transfer will be TLS encrypted and INSTAR does not store any copies of your Emails!`}</p>
    </blockquote>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "716px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/b53f979243fa67396e2e36ae788eed5c/6bbf7/WebUI-Alarm_SMTP.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "104.34782608695652%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAVCAIAAADJt1n/AAAACXBIWXMAAAsSAAALEgHS3X78AAADAUlEQVQ4y32U72/aRhjH+be2/R1r9zr/yPqyk7a+yIsuCklb1gSkVQs0jQFjB1hZHGJiG8Mdto8fxj77wPbZBlLTvNxIohKSaB+dTnpO+ug5PV/dpY4/ffrx2fPvvv/h2fOfBEFQFKXVasmSrGmavommaaPRiGGYra2tdDq9t7eXYhjm9evfX778ZX9/fzDoq2pHbbcVRfE8PwzpA8IwDIIgk8nwPF+v11O5XC6XzRbyhVP+FGM8Gg4BgISQ8LFKqed5hJBKpSJJkqIoqffv/9je3j44PGRZ1rKs0XCIdOT7QRzH0SZxHAdB4LouwzCiKF5eXqay2cPddDqfz3McZ1mWYRgQQl3Xp9NpFEXhI4IgWMu5bPbN27f5/BHLsrZtI9TXNR1j7HkefQrf99dyJvNuZ2dnd3fV3Lbt/mAQUHp1dbVYLOaPWCwWlNK1/NfR0Yc/PzSbF41GA2OMDNTrQc/3giB4si0hpFgs3smu42Abx1EchiEhxDBQD8KxOY6i8HFO0+nUtu21TFx30B/EUTybzVzXRQZqt9sAdE3TjOPV4X3m8/nGtQuFjy9+fvHqt18/FgqUUoSQrmsIIdd1w6fYmLahG1qvN51MHMchhCCE1E7HHJmL+Tx6Cs/z1vIqd+wsl1+TJCGuC7rgvNmEECKEbBtTSh9MbqNzuVw6Pj4uFov/nJ1hjLFt9/t93/cXNyyXyyRJvtwjDMO1zHN8rVqrVDiO4zDGlmUNh0OEkHGDLEvdbtcwjG9va2PaoAsg7AEIZVl2HMcwDACApmurpWmtVktV1dv3qGkaAGA8HpdKpTtZEIRSqcSybPPiYjKZBEEAAVBu6KgdAGCnrQIAb0vTNDeiajQaPM9zHCcIAiFkNpt5Uw9jx3Ec13EHg6Ft2ZMJwRjfhhdF0VoWRbFSqVSrVVEUv2UbxXEUrzZK6f2cbqd9cnJyJ5+fC6enq2+h2WxSSq+vr5MkWf4v5XL5TpZl6fPnv88EQZZlQRBqtVr9Hg/Ker1erVYPDg4kSfpP/hcEdx3dutuK2AAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b53f979243fa67396e2e36ae788eed5c/e4706/WebUI-Alarm_SMTP.avif 230w", "/en/static/b53f979243fa67396e2e36ae788eed5c/d1af7/WebUI-Alarm_SMTP.avif 460w", "/en/static/b53f979243fa67396e2e36ae788eed5c/04ac8/WebUI-Alarm_SMTP.avif 716w"],
              "sizes": "(max-width: 716px) 100vw, 716px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/b53f979243fa67396e2e36ae788eed5c/a0b58/WebUI-Alarm_SMTP.webp 230w", "/en/static/b53f979243fa67396e2e36ae788eed5c/bc10c/WebUI-Alarm_SMTP.webp 460w", "/en/static/b53f979243fa67396e2e36ae788eed5c/d8378/WebUI-Alarm_SMTP.webp 716w"],
              "sizes": "(max-width: 716px) 100vw, 716px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b53f979243fa67396e2e36ae788eed5c/81c8e/WebUI-Alarm_SMTP.png 230w", "/en/static/b53f979243fa67396e2e36ae788eed5c/08a84/WebUI-Alarm_SMTP.png 460w", "/en/static/b53f979243fa67396e2e36ae788eed5c/6bbf7/WebUI-Alarm_SMTP.png 716w"],
              "sizes": "(max-width: 716px) 100vw, 716px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/b53f979243fa67396e2e36ae788eed5c/6bbf7/WebUI-Alarm_SMTP.png",
              "alt": "Web User Interface - 720p Series - Alarm SMTP Server",
              "title": "Web User Interface - 720p Series - Alarm SMTP Server",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <SMTPTable mdxType="SMTPTable" />
    <EuiSpacer mdxType="EuiSpacer" />
    <DangerBox mdxType="DangerBox" />
    <h3 {...{
      "id": "server-presets",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#server-presets",
        "aria-label": "server presets permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Server Presets`}</h3>
    <p>{`Simply use the `}<strong parentName="p">{`INSTAR Preset`}</strong>{` if you don´t know the SMTP information of your Email provider. Choose the preset from the drop down menu and confirm. The Email recipient can be set in the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/720p_Series/Alarm/Email/"
      }}>{`Email Menu`}</a>{`. Set-up this way, your camera will use an INSTAR Server to send alarm mails to your personal Email address. This transfer will be TLS encrypted and INSTAR does not store any copies of your Emails!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      